import { FETCH_PRODUCTS_SUCCESS } from "../actions/productActions";
// import { GET_PRODUCTS_SUCCESS} from "../actions/productActions"

const initState = {
  products: [],
  // products2:[]
};

const productReducer = (state = initState, action) => {
  if (action.type === FETCH_PRODUCTS_SUCCESS) {
    return {
      ...state,
      products: action.payload
    };
  }
  // else if(action.type === GET_PRODUCTS_SUCCESS){
  //   return {
  //     ...state,
  //     products2: action.payload
  //   };
  // }

  return state;
};

export default productReducer;
