export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

// export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch products
export const fetchProducts = products => {
  return dispatch => {
    dispatch(fetchProductsSuccess(products));
  };
};

// // get products2
// const getProductsSuccess = products2 => ({
//   type: GET_PRODUCTS_SUCCESS,
//   payload: products2
// });

// export function getProducts(categoryId) {
//   return function(dispatch) {
//     let url = "http://localhost:53726/api/products/";
//     if (categoryId) {
//       url = url + "getAllActiveProductsByCategoryId/" + categoryId;
//     }else{
//       url = url + "getAllProducts";
//     }
//     return fetch(url)
//       .then(response => response.json())
//       .then(result => dispatch(getProductsSuccess(result)));
//   };
// }
