import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";


// home pages
const home = lazy(() => import("./pages/home/home"));
//products
const ShopGridNoSidebar = lazy(() => import("./pages/products/ShopGridNoSidebar"));
const Product = lazy(() => import("./pages/products/Product"));
const ShopGridStandard = lazy(() => import("./pages/products/ShopGridStandard"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));

const ShopGridNoSideBarWithProductLabel = lazy(() => import("./pages/products/ShopGridNoSideBarWithProductLabel"));


// const DiningRooms = lazy(() => import("./pages/products/DiningRooms"));
// const Bedrooms = lazy(() => import("./pages/products/Bedrooms"));
// const TvWallUnits = lazy(() => import("./pages/products/TvWallUnits"));
// const TeenagerRooms = lazy(() => import("./pages/products/TeenagerRooms"));
// const KitchenTables = lazy(() => import("./pages/products/KitchenTables"));
// const Accessories = lazy(() => import("./pages/products/Accessories"));
// const BedAndBase = lazy(() => import("./pages/products/BedAndBase"));
// const HomeFurniture = lazy(() => import("./pages/home/HomeFurniture"));
// const HomeFurnitureTwo = lazy(() => import("./pages/home/HomeFurnitureTwo"));
// const HomeFurnitureThree = lazy(() =>
//   import("./pages/home/HomeFurnitureThree")
// );
// const HomeFurnitureFour = lazy(() => import("./pages/home/HomeFurnitureFour"));
// const HomeFurnitureFive = lazy(() => import("./pages/home/HomeFurnitureFive"));
// const HomeFurnitureSix = lazy(() => import("./pages/home/HomeFurnitureSix"));
// const HomeFurnitureSeven = lazy(() =>
//   import("./pages/home/HomeFurnitureSeven")
// );

// shop pages
//const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
// const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
// const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const ShopPhotos = lazy(() => import("./pages/other/ShopPhotos"));
const WarrantyConditions = lazy(() => import("./pages/other/WarrantyConditions"));
const ProductMaintenance = lazy(() => import("./pages/other/ProductMaintenance"));
const ProductShippingAbroad = lazy(() => import("./pages/other/ProductShippingAbroad"));
const WhyUs = lazy(() => import("./pages/other/WhyUs"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));


const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          tr: require("./translations/turkish.json"),
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json")
        }
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={home}
                />

                {/* Homepages */}

                <Route
                  path={process.env.PUBLIC_URL + "/home"}
                  component={home}
                />

                {/*Products*/}

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/kategoriler/:categoryName/:id"}
                  component={ShopGridNoSidebar}
                />

                {/* <Route
                  exact
                  path={process.env.PUBLIC_URL + "/urun/:productName/:id"}
                  component={Product}
                /> */}

                <Route
                  path={process.env.PUBLIC_URL + "/urun/:productName/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/tum-urunler"}
                  component={ShopGridStandard}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/begendiklerim"}
                  component={Wishlist}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/karsilastir"}
                  component={Compare}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/etiketli-urunler/:productLabel/:id"}
                  component={ShopGridNoSideBarWithProductLabel}
                />
                {/* <Route
                  path={process.env.PUBLIC_URL + "/dining-rooms"}
                  component={DiningRooms}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/bedrooms"}
                  component={Bedrooms}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/tv-wall-units"}
                  component={TvWallUnits}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/teenager-rooms"}
                  component={TeenagerRooms}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/kitchen-tables"}
                  component={KitchenTables}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/accessories"}
                  component={Accessories}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/bed-base"}
                  component={BedAndBase}
                /> */}

                {/* <Route
                  path={process.env.PUBLIC_URL + "/home-furniture"}
                  component={HomeFurniture}
                /> */}




                {/* <Route
                  path={process.env.PUBLIC_URL + "/home-furniture"}
                  component={HomeFurniture}
                /> */}
                {/* <Route
                  path={process.env.PUBLIC_URL + "/home-furniture-two"}
                  component={HomeFurnitureTwo}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/home-furniture-three"}
                  component={HomeFurnitureThree}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/home-furniture-four"}
                  component={HomeFurnitureFour}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/home-furniture-five"}
                  component={HomeFurnitureFive}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/home-furniture-six"}
                  component={HomeFurnitureSix}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/home-furniture-seven"}
                  component={HomeFurnitureSeven}
                /> */}










                {/* Shop pages */}

                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                  component={ShopGridFilter}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                  component={ShopGridTwoColumn}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}
                  component={ShopGridNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar2"}
                  component={ShopGridNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar3"}
                  component={ShopGridNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar4"}
                  component={ShopGridNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar5"}
                  component={ShopGridNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar6"}
                  component={ShopGridNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar7"}
                  component={ShopGridNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                  component={ShopGridFullWidth}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                  component={ShopGridRightSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-list-standard"}
                  component={ShopListStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-list-full-width"}
                  component={ShopListFullWidth}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-list-two-column"}
                  component={ShopListTwoColumn}
                />

                {/* Shop product pages */}
                {/* <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                /> */}
                <Route
                  path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                  component={ProductTabLeft}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                  component={ProductTabRight}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                  component={ProductSticky}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-slider/:id"}
                  component={ProductSlider}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                  component={ProductFixedImage}
                />

                {/* Blog pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog-standard"}
                  component={BlogStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                  component={BlogNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                  component={BlogRightSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details-standard"}
                  component={BlogDetailsStandard}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/neden-biz"}
                  component={WhyUs}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/hakkimizda"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/iletisim"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/magazalarimiz"}
                  component={ShopPhotos}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/garanti-kosullari"}
                  component={WarrantyConditions}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/urun-bakimi"}
                  component={ProductMaintenance}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/yurt-disi-mobilya-gonderimi"}
                  component={ProductShippingAbroad}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />


                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));

